import { Cookies } from "react-cookie";
import jwt from "jsonwebtoken";

export const setSession = (token) => {
  let cookies = new Cookies();
  if (token) cookies.set("ccr-t", token, { path: "/" });
  else cookies.remove("ccr-t", { path: "/" });
};

export const setClient = (client) => {
  let cookies = new Cookies();
  if (client) {
    cookies.set("client_id", client._id, { path: "/" });

    // Check if dateformat exists, otherwise use default value
    const dateFormat = client.dateformat || "MM/DD/YYYY";
    cookies.set("client_dateFormat", dateFormat, { path: "/" });

    // Check if timezone exists, otherwise use browser timezone
    const timezone = client.timezone || getDefaultTimeZone();
    cookies.set("client_timezone", timezone, { path: "/" });

    // Check if isAM_PM exists, otherwise set it to false
    const isAM_PM = client.isAM_PM !== undefined || "" ? client.isAM_PM : true;
    cookies.set("client_isAM_PM", isAM_PM, { path: "/" });

    const labels = client.labels || {};
    cookies.set("client_labels", labels, { path: "/" });
  } else {
    cookies.remove("client_id", { path: "/" });
    cookies.remove("client_dateFormat", { path: "/" });
    cookies.remove("client_timezone", { path: "/" });
    cookies.remove("client_isAM_PM", { path: "/" });
    cookies.remove("client_labels", { path: "/" });
  }
};

// Function to get default timezone in the specified format
const getDefaultTimeZone = () => {
  const browserTimeZone = "America/New_York";
  return browserTimeZone;
};

const getUserToken = () => {
  const user = getLoggedInUser();
  return user;
};

export const getUserClient = () => {
  const cookies = new Cookies();
  const client_id = cookies.get("client_id");
  const client_dateFormat = cookies.get("client_dateFormat");
  const client_timezone = cookies.get("client_timezone");
  const client_isAM_PM = cookies.get("client_isAM_PM");
  const client_labels = cookies.get("client_labels");
  const client = {
    client_id,
    client_dateFormat,
    client_timezone,
    client_isAM_PM,
    client_labels,
  };
  return client;
};

export const getLoggedInUser = () => {
  const cookies = new Cookies();
  const user = cookies.get("ccr-t");

  return user
    ? typeof jwt.decode(user) == "object"
      ? user
      : JSON.parse(user)
    : null;
};

export const getDecodedLoggedInUser = () => {
  return jwt.decode(getLoggedInUser());
};

export const isUserAuthenticated = () => {
  const user = getLoggedInUser();

  if (!user) {
    return false;
  } else {
    return true;
  }
};

export const loadHeaders = () => {
  const config = {
    headers: {
      "Content-type": "Application/json",
      "x-auth-token": getUserToken(),
    },
  };
  return config;
};

export const loadAWSS3Headers = (file) => {
  const config = {
    headers: {
      "Content-type": file.type,
    },
  };
  return config;
};

export const rateMap = {
  onetime: "One Time",
  weekly: "Weekly",
  biweekly: "Biweekly",
  monthly: "Monthly",
};

export const getDifference = (currentDate, startDate) => {
  // const days = parseInt((currentDate - startDate) / (1000 * 60 * 60 * 24));
  let hours = parseInt(
    (Math.abs(currentDate - startDate) / (1000 * 60 * 60)) % 24
  );
  let minutes = parseInt(
    (Math.abs(currentDate.getTime() - startDate.getTime()) / (1000 * 60)) % 60
  );
  let seconds = parseInt(
    (Math.abs(currentDate.getTime() - startDate.getTime()) / 1000) % 60
  );
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return { hours: hours, minutes: minutes, seconds: seconds };
};

export const datePickerFormat = {
  "MM/DD/YYYY": "MM/dd/yyy",
  "YY/MM/DD": "yy/MM/dd",
  "DD/MM/YYYY": "dd/MM/yyyy",
  "YYYY/MM/DD": "yyyy/MM/dd",
  "MMMM DD, YYYY": "MMMM dd, yyyy",
  "MMM DD, YYYY": "MMM dd, yyyy",
  "DD MMMM, YYYY": "dd MMMM, yyyy",
  "DD MMM, YYYY": "dd MMM, yyyy",
  "YYYY-MM-DD": "yyyy-MM-dd",
  "YYYY.MM.DD": "yyyy.MM.dd",
  "YYYY-DDD": "yyyy-ddd",
};

export function toTitleCase(str) {
  return str.toLowerCase().replace(/(^|\s)\w/g, function (letter) {
    return letter.toUpperCase();
  });
}

export function getPlural(str) {
  return str + "s";
}

export function getCustomerLabel() {
  return getUserClient().client_labels?.customer || "Customer";
}

export function getAgreementLabel() {
  return getUserClient().client_labels?.agreement || "Agreement";
}

export function getVehicleLabel() {
  return getUserClient().client_labels?.vehicle || "Vehicle";
}

export function getPickupLabel() {
  return getUserClient().client_labels?.pickup || "Pick up";
}

export function getReturnLabel() {
  return getUserClient().client_labels?.returns || "Return";
}

export function getVehiclePlateNumberLabel() {
  return getUserClient().client_labels?.plateNumber || "Plate Number";
}

export function getVehicleVINLabel() {
  return getUserClient().client_labels?.vin || "VIN";
}

export function getVehicleStateInspectionExpirationDateLabel() {
  return (
    getUserClient().client_labels?.stateInspectionExpirationDate ||
    "State Inspection Expiration Date"
  );
}

export function getVehicleRegistrationExpirationDateLabel() {
  return (
    getUserClient().client_labels?.registrationExpirationDate ||
    "Registration Expiration Date"
  );
}

export function getVehicleTLCInspectionExpirationDateLabel() {
  return (
    getUserClient().client_labels?.tlcInspectionExpirationDate ||
    "TLC Inspection Expiration Date"
  );
}

export function getVehicleInsuranceExpirationDateLabel() {
  return (
    getUserClient().client_labels?.insuranceExpirationDate ||
    "Insurance Expiration Date"
  );
}

export function getLocalLicenseNumberLabel() {
  return (
    getUserClient().client_labels?.localLicenseNumber || "TLC License Number"
  );
}

export function getLocalBaseNameLabel() {
  return getUserClient().client_labels?.localBaseName || "TLC Base Name";
}

export function getMileageLabel() {
  return getUserClient().client_labels?.mileage || "Mileage";
}

export function getLockKeyLabel() {
  return getUserClient().client_labels?.lockKey || "Lock Key";
}
